import { Injectable } from "@angular/core";
import { ServiceTypeEnum } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceTypesService { 
  
  isAllServiceTypeSelected(serviceTypeCsv: string): boolean;
  isAllServiceTypeSelected(serviceTypeArray: number[]): boolean;
  isAllServiceTypeSelected(serviceType: string | number[]): boolean {
    let originalArray: number[];
  
    if (typeof serviceType === 'string') {
      if (!serviceType) return false;
      originalArray = serviceType.split(",").map(Number);
    } else {
      originalArray = serviceType;
    }
  
    // Remove duplicates
    const uniqueArray: number[] = [...new Set(originalArray)];
  
    // If the unique array length is equal to the number of service types, then all service types are selected
    // Subtract 1 from the length because the first element in the enum is None
    return uniqueArray.length == (Object.keys(ServiceTypeEnum).length - 1);
  }

  getEnumValueByIndex(index: number): string | undefined {
    const keys = Object.keys(ServiceTypeEnum).filter(key => isNaN(Number(key)));
    const enumKey = keys[index];
  
    return enumKey;
  }
}